import { Injectable } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { IdentityService } from 'src/app/services/identity/identity.service';
import { NavigationEnd, Router } from '@angular/router';
import { PersonalSettingsService } from 'src/app/modules/shared/services/personal-settings.service';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/modules/shared/services/data/data.service';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  isSidebarOpen = true;
  menuItems$ = new BehaviorSubject<MenuItem[]>(new Array<MenuItem>());
  isSidebarOpen$ = new BehaviorSubject<boolean>(false);

  constructor(
    private t: TranslateService,
    private identity: IdentityService,
    private settings: PersonalSettingsService,
    router: Router,
    private data: DataService
  ) {
    this.setMenu().then();
    this.identity.$identityUser.subscribe(async () => {
      await this.setMenu();
    });
    this.t.onLangChange.subscribe(async () => {
      await this.setMenu();
    });
    this.isSidebarOpen$.subscribe(async isSidebarOpen => {
      this.isSidebarOpen = isSidebarOpen;
      await this.setMenu();
    });
    router.events.subscribe(val => {
      if (val instanceof NavigationEnd && window.innerWidth <= 1400) {
        this.setIsMenuOpen(false);
      }
    });
  }

  async setMenu() {
    const user = await this.identity.getLoggedInUser();

    const activeGameDayMenu = {
      label: this.isSidebarOpen ? this.t.instant('menu.game_day.name') : '',
      icon: 'pi pi-list',
      expanded: true,
      visible:
        this.identity.hasUserPermission(user, 'game_days.check_in') ||
        this.identity.hasUserPermission(user, 'game_days.shop') ||
        this.identity.hasUserPermission(user, 'game_days.chrono') ||
        this.identity.hasUserPermission(user, 'game_days.violations'),
      items: [
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.game_day.checkin')
            : '',
          icon: 'pi pi-ticket',
          routerLink: '/game-day/checkin',
          visible: this.identity.hasUserPermission(user, 'game_days.check_in'),
        },
        {
          label: this.isSidebarOpen ? this.t.instant('menu.game_day.shop') : '',
          icon: 'pi pi-shopping-bag',
          routerLink: '/game-day/shop',
          visible: this.identity.hasUserPermission(user, 'game_days.shop'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.game_day.chroni')
            : '',
          icon: 'pi pi-briefcase',
          routerLink: '/game-day/chroni',
          visible: this.identity.hasUserPermission(user, 'game_days.chrono'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.game_day.violations')
            : '',
          icon: 'pi pi-exclamation-triangle',
          routerLink: '/game-day/violations',
          visible: this.identity.hasUserPermission(
            user,
            'game_days.violations'
          ),
        },
      ],
    };
    const shoppingListMenu = {
      label: this.isSidebarOpen
        ? this.t.instant('menu.admin.shopping_list.list')
        : '',
      icon: 'pi pi-shopping-cart',
      expanded: true,
      routerLink:
        this.identity.hasUserPermission(user, 'shopping_list.preset') ||
        this.identity.hasUserPermission(user, 'shopping_list.category')
          ? undefined
          : '/shopping-list/entries',
      visible:
        this.identity.hasUserPermission(user, 'shopping_list') ||
        this.identity.hasUserPermission(user, 'shopping_list.preset') ||
        this.identity.hasUserPermission(user, 'shopping_list.category'),
      items:
        !this.identity.hasUserPermission(user, 'shopping_list.preset') &&
        !this.identity.hasUserPermission(user, 'shopping_list.category')
          ? undefined
          : [
              {
                label: this.isSidebarOpen
                  ? this.t.instant('menu.admin.shopping_list.category')
                  : '',
                icon: 'pi pi-cart-arrow-down',
                routerLink: '/shopping-list/categories',
                visible: this.identity.hasUserPermission(
                  user,
                  'shopping_list.category'
                ),
              },
              {
                label: this.isSidebarOpen
                  ? this.t.instant('menu.admin.shopping_list.preset')
                  : '',
                icon: 'pi pi-cart-plus',
                routerLink: '/shopping-list/presets',
                visible: this.identity.hasUserPermission(
                  user,
                  'shopping_list.preset'
                ),
              },
              {
                label: this.isSidebarOpen
                  ? this.t.instant('menu.admin.shopping_list.list')
                  : '',
                icon: 'pi pi-list-check',
                routerLink: '/shopping-list/entries',
                visible: this.identity.hasUserPermission(user, 'shopping_list'),
              },
            ],
    };
    const equipmentMenu = {
      label: this.isSidebarOpen
        ? this.t.instant('menu.admin.rental.equipment')
        : '',
      icon: 'pi pi-shopping-bag',
      expanded: true,
      visible:
        this.identity.hasUserPermission(user, 'rental_packages') ||
        this.identity.hasUserPermission(user, 'rental_items') ||
        this.identity.hasUserPermission(user, 'rental_objects') ||
        this.identity.hasUserPermission(user, 'bbs'),
      items: [
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.admin.rental.objects')
            : '',
          icon: 'pi pi-pistol',
          iconStyle: {
            minHeight: '16px',
            minWidth: '16px',
            transform: 'scaleX(-1)',
            webkitTransform: 'scaleX(-1)',
          },
          routerLink: '/equipment/rental/objects',
          visible: this.identity.hasUserPermission(user, 'rental_objects'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.admin.rental.items')
            : '',
          icon: 'pi pi-tag',
          routerLink: '/equipment/rental/items',
          visible: this.identity.hasUserPermission(user, 'rental_items'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.admin.rental.package')
            : '',
          icon: 'pi pi-tags',
          routerLink: '/equipment/rental/packages',
          visible: this.identity.hasUserPermission(user, 'rental_packages'),
        },
        {
          label: this.isSidebarOpen ? this.t.instant('menu.admin.bbs') : '',
          icon: 'pi pi-spinner-dotted',
          routerLink: '/equipment/bbs',
          visible: this.identity.hasUserPermission(user, 'bbs'),
        },
      ],
    };
    const adminMenu = {
      label: this.isSidebarOpen ? this.t.instant('menu.admin.name') : '',
      icon: 'pi pi-cog',
      expanded: true,
      visible:
        this.identity.hasUserPermission(user, 'players') ||
        this.identity.hasUserPermission(user, 'teams') ||
        this.identity.hasUserPermission(user, 'game_days') ||
        this.identity.hasUserPermission(user, 'ribbons'),
      items: [
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.admin.nfc_tags')
            : '',
          icon: 'pi pi-wifi',
          routerLink: '/admin/nfc-tags',
          visible: this.identity.hasUserPermission(user, 'players'),
        },
        {
          label: this.isSidebarOpen ? this.t.instant('menu.admin.players') : '',
          icon: 'pi pi-user',
          routerLink: '/admin/players',
          visible: this.identity.hasUserPermission(user, 'players'),
        },
        {
          label: this.isSidebarOpen ? this.t.instant('menu.admin.teams') : '',
          icon: 'pi pi-users',
          routerLink: '/admin/teams',
          visible: this.identity.hasUserPermission(user, 'teams'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.admin.violations')
            : '',
          icon: 'pi pi-exclamation-circle',
          routerLink: '/admin/violations',
          visible: this.identity.hasUserPermission(user, 'violations'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.admin.game_days')
            : '',
          icon: 'pi pi-calendar-times',
          routerLink: '/admin/game-days',
          visible: this.identity.hasUserPermission(user, 'game_days'),
        },
        {
          label: this.isSidebarOpen ? this.t.instant('menu.admin.ribbons') : '',
          icon: 'pi pi-money-bill',
          routerLink: '/admin/ribbons',
          visible: this.identity.hasUserPermission(user, 'ribbons'),
        },
      ],
    };
    const managementMenu = {
      label: this.isSidebarOpen ? this.t.instant('menu.management.name') : '',
      icon: 'pi pi-wrench',
      expanded: true,
      visible:
        this.identity.hasUserPermission(user, 'users') ||
        this.identity.hasUserPermission(user, 'roles') ||
        this.identity.hasUserPermission(user, 'api_keys'),
      items: [
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.management.users')
            : '',
          icon: 'pi pi-user',
          routerLink: '/administration/users',
          visible: this.identity.hasUserPermission(user, 'users'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.management.roles')
            : '',
          icon: 'pi pi-verified',
          routerLink: '/administration/roles',
          visible: this.identity.hasUserPermission(user, 'roles'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.management.api_keys')
            : '',
          icon: 'pi pi-key',
          routerLink: '/administration/api-keys',
          visible: this.identity.hasUserPermission(user, 'api_keys'),
        },
        {
          label: this.isSidebarOpen
            ? this.t.instant('menu.management.legacy_sync')
            : '',
          icon: 'pi pi-file-excel',
          routerLink: '/administration/legacy-sync',
          visible: this.identity.hasUserPermission(user, 'legacy_sync'),
        },
      ],
    };

    const menuItems: MenuItem[] = [
      {
        label: this.isSidebarOpen ? this.t.instant('menu.dashboard') : '',
        icon: 'pi pi-th-large',
        routerLink: '/dashboard',
      },
      activeGameDayMenu,
      shoppingListMenu,
      equipmentMenu,
      adminMenu,
      managementMenu,
      {
        label: this.isSidebarOpen
          ? this.t.instant('roles.permissions.external.easy_redirect')
          : '',
        icon: 'pi pi-external-link',
        command: () => {
          window.open(environment.external.easyRedirect, '_blank');
        },
        visible: this.identity.hasUserPermission(
          user,
          'external.easy_redirect'
        ),
      },
      {
        label: this.isSidebarOpen
          ? this.t.instant('roles.permissions.external.cloud')
          : '',
        icon: 'pi pi-external-link',
        command: () => {
          window.open(environment.external.cloud, '_blank');
        },
        visible: this.identity.hasUserPermission(user, 'external.cloud'),
      },
    ];

    this.data.getActiveGameDay().subscribe(gameDay => {
      if (!gameDay) {
        this.menuItems$.next(menuItems);
        return;
      }
      activeGameDayMenu.items.push({
        label: this.isSidebarOpen ? this.t.instant('game_day.squads') : '',
        icon: 'pi pi-arrow-right-arrow-left',
        routerLink: `/admin/game-days/${gameDay.id}/squads`,
        visible: this.identity.hasUserPermission(user, 'game_days.edit'),
      });
      activeGameDayMenu.items.push({
        label: this.isSidebarOpen ? this.t.instant('game_day.attendees') : '',
        icon: 'pi pi-users',
        routerLink: `/admin/game-days/${gameDay.id}/attendees`,
        visible: this.identity.hasUserPermission(user, 'game_days.edit'),
      });
      this.menuItems$.next(menuItems);
    });
  }

  setIsMenuOpen(isMenuOpen: boolean) {
    this.settings.setSetting('isMenuOpen', isMenuOpen);
    this.setSideWidth(isMenuOpen);
  }

  setSideWidth(isSidebarOpen: boolean): void {
    this.isSidebarOpen$.next(isSidebarOpen);
  }
}
