<main *ngIf="isLoggedIn">
  <app-shortcut [shortcuts]="shortcuts"></app-shortcut>
  <app-header></app-header>

  <div class="app">
    <aside
      [ngClass]="{
        'sidebar-open': isSidebarOpen,
        'sidebar-closed': !isSidebarOpen,
      }">
      <app-sidebar></app-sidebar>
    </aside>
    <section class="component">
      <router-outlet></router-outlet>
    </section>
  </div>
  <app-footer></app-footer>

  <p-toast></p-toast>
  <p-confirmDialog #cd key="confirmConfirmationDialog" [baseZIndex]="10000">
    <ng-template pTemplate="footer">
      <div class="wrapper-right btn-wrapper">
        <p-button
          label="{{ 'dialog.abort' | translate }}"
          class="btn icon-btn danger-icon-btn"
          icon="pi pi-times-circle"
          (onClick)="cd.reject()"></p-button>
        <p-button
          label="{{ 'dialog.confirm' | translate }}"
          class="btn"
          icon="pi pi-check-circle"
          (onClick)="cd.accept()"></p-button>
      </div>
    </ng-template>
  </p-confirmDialog>
  <app-player-profile-dialog></app-player-profile-dialog>
</main>
<app-spinner></app-spinner>
<app-terminal *ngIf="devConsole" [(visible)]="devConsole"></app-terminal>
