<div class="content-row">
  <div class="content-column"></div>
</div>
<div class="content-wrapper">
  <div class="content-header">
    <h2>
      {{ 'common.error' | translate }}
    </h2>
  </div>

  <div class="content">
    {{ 'common.404' | translate }}
  </div>
</div>
