import {
  APP_INITIALIZER,
  ErrorHandler,
  NgModule,
  isDevMode,
} from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from 'src/app/app.component';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  AbstractSecurityStorage,
  AuthModule,
  LogLevel,
} from 'angular-auth-oidc-client';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { SharedModule } from 'src/app/modules/shared/shared.module';
import { TokenInterceptorService } from 'src/app/modules/shared/providers/token-interceptor.service';
import { Logger } from 'src/app/services/logger/logger.service';
import { FooterComponent } from 'src/app/components/footer/footer.component';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { GraphQLModule } from 'src/app/modules/graphql/graphql.module';
import { RibbonService } from 'src/app/services/ribbons/ribbon.service';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { SidebarComponent } from 'src/app/components/sidebar/sidebar.component';
import { SpinnerComponent } from 'src/app/components/spinner/spinner.component';
import { NotFoundComponent } from 'src/app/components/error/not-found/not-found.component';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { ErrorHandlingService } from 'src/app/services/error-handling/error-handling.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SecurityLocalStorageService } from 'src/app/services/security-local-storage-service';

if (environment.production) {
  Logger.enableProductionMode();
} else {
  Logger.enableDevMode();
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    SpinnerComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule.forRoot({
      config: {
        authority: environment.auth.authority,
        redirectUrl: environment.auth.redirectUrl,
        postLogoutRedirectUri: environment.auth.postLogoutRedirectUri,
        clientId: 'Admin',
        scope: 'openid api offline_access',
        responseType: 'code',
        silentRenew: true,
        useRefreshToken: true,
        autoUserInfo: false,
        logLevel: LogLevel.Warn,
      },
    }),
    GraphQLModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  exports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    GraphQLModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: configurationFactory,
      deps: [SettingsService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorHandlingService,
    },
    MessageService,
    ConfirmationService,
    DialogService,
    RibbonService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    },
    {
      provide: AbstractSecurityStorage,
      useClass: SecurityLocalStorageService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function configurationFactory(
  settingsService: SettingsService
): () => Promise<unknown> {
  return () => settingsService.loadSettings();
}
