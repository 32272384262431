import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';

const uri = environment.api.url + '/graphql';

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          link: httpLink.create({ uri }),
          cache: new InMemoryCache(),
          defaultOptions: {
            watchQuery: {
              fetchPolicy: 'no-cache',
            },
            query: {
              fetchPolicy: 'no-cache',
            },
            mutate: {
              fetchPolicy: 'no-cache',
            },
          },
        };
      },
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
