export interface Shortcut {
  key: string;
  description: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  command: (selected: any[]) => void;
}

export enum ModifierKeys {
  Ctrl = 'Control',
  Alt = 'Alt',
  AltGr = 'AltGraph',
  Shift = 'Shift',
  MacCmd = 'Meta', // Für Mac: Cmd-Taste
  Windows = 'OS', // Für Windows: Windows-Taste
}
