import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings/settings.service';
import { SpinnerService } from 'src/app/services/spinner/spinner.service';
import { catchError, throwError } from 'rxjs';
import { DataService } from 'src/app/modules/shared/services/data/data.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  public frontendVersion = '0.0.0.0';
  public backendVersion = '0.0.0.0';

  constructor(
    private settings: SettingsService,
    private data: DataService,
    private spinner: SpinnerService
  ) {}

  ngOnInit(): void {
    this.frontendVersion =
      this.settings.versionSettings?.WebVersion ?? '0.0.0.0';

    this.spinner.show();
    this.data
      .getApiVersion()
      .pipe(
        catchError(error => {
          this.spinner.hide();
          return throwError(() => error);
        })
      )
      .subscribe(version => {
        this.spinner.hide();
        this.backendVersion = version;
      });
  }

  get privacyUrl(): string {
    return this.settings.getPrivacyURL();
  }

  get imprintUrl(): string {
    return this.settings.getImprintURL();
  }
}
